<template>
  <b-container class="mt-2 container-max-width">
    <masthead title="Forms Library" :show-search="false">
      <router-link :to="{ name: 'dashpanel' }" class="link-color">
        <fa-icon icon="chevron-left" class="fa-xs" />
        Home
      </router-link>
    </masthead>
    <b-row>
      <b-col class="pb-4">
        <p class="mb-4">
          The Forms Library is a collection of resources to allow you to complete and submit a filing on your own.
          Depending on which filing methods your state offers, these may include forms to fill out and submit or links to state websites for online filing.
          Select your state and jurisdiction to see available forms and resources.
        </p>
        <a class="link-underline link-color"
           aria-label="Forms Library | Frequently Asked Questions Link"
           @click="logFaqCategoryNavigation(faqCategoryId, faqInteraction)">
          Forms Library | Frequently Asked Questions
          <arrow-right class="arrow-icon" />
        </a>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <span class="mb-3">Select a company</span>
        <company-select
          class="mb-3"
          @input="companySelected"
        />
      </b-col>
      <b-col>
        <span class="mb-3">Select jurisdiction</span>
        <jurisdiction-select
          class="mb-3"
          :is-disable="disableJurisdictionSelect"
          @input="jurisdictionSelectHandler"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-button
          variant="primary"
          :class="$mq != 'sm' ? 'btn-shadow mr-2' : ''"
          class="p-2 btn-border-radius w-100 add-service-btn"
          aria-label="Add a business to my account Button"
          @click="newCompanyPage"
        >
          Add a business to my account
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
  import Masthead from '@/components/shared/Masthead'
  import CompanySelect from '@/components/CompanySelect'
  import JurisdictionSelect from '@/components/JurisdictionSelect'
  import { faqMixin } from '@/mixins/faqMixin'

  export default {
    name: 'CompanySelection',
    mixins: [faqMixin],
    components: {
      ArrowRight: () => import('@images/ui/arrow-right-2FB2AE.svg'),
      CompanySelect,
      JurisdictionSelect,
      Masthead,
    },
    computed: {
      ...mapGetters('companies', ['companies']),
    },
    methods: {
      companySelected(companyId) {
        this.selectedCompanyId = companyId
        if(this.selectedCompanyId != null){
          this.disableJurisdictionSelect = false
        }
      },

      jurisdictionSelectHandler(jurisdictionId) {
        this.$router.push({
          name: 'jurisdictionFilings',
          params: {
            companyId: this.selectedCompanyId,
            jurisdictionId: jurisdictionId,
          },
        })
      },
      newCompanyPage() {
        this.$router.push({ name: 'createCompany', params: { origin: 'formsLibrary' } })
      },
    },
    data() {
      return {
        selectedCompanyId: null,
        disableJurisdictionSelect: true,
        faqCategoryId: null,
        faqInteraction: { type: 'link', action: 'nav-from-page', categoryName: 'Forms Library', timestamp: Date.now() },
      }
    },
    async mounted() {
      if (this.faqInformation.length === 0) {
        await this.fetchFaqInformation()
      }
      this.faqCategoryId = this.faqInformation.find(item => item.name === "Forms Library")?.id
    },
  }
</script>

<style lang="scss" scoped>
  .btn-border-radius { border-radius: 3px !important; }
  .container-max-width { max-width: 62rem; }
  .link-color { color: #2FB2AE !important; }
  .link-underline { text-decoration: underline !important; }
  .link-color:hover {
    cursor: pointer;
  }
  @media only screen and (min-width: 768px) {
    .add-service-btn {
      width: 48% !important;
    }
  }
</style>
