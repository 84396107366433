var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "mt-2 container-max-width" },
    [
      _c(
        "masthead",
        { attrs: { title: "Forms Library", "show-search": false } },
        [
          _c(
            "router-link",
            { staticClass: "link-color", attrs: { to: { name: "dashpanel" } } },
            [
              _c("fa-icon", {
                staticClass: "fa-xs",
                attrs: { icon: "chevron-left" },
              }),
              _vm._v("\n      Home\n    "),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c("b-col", { staticClass: "pb-4" }, [
            _c("p", { staticClass: "mb-4" }, [
              _vm._v(
                "\n        The Forms Library is a collection of resources to allow you to complete and submit a filing on your own.\n        Depending on which filing methods your state offers, these may include forms to fill out and submit or links to state websites for online filing.\n        Select your state and jurisdiction to see available forms and resources.\n      "
              ),
            ]),
            _c(
              "a",
              {
                staticClass: "link-underline link-color",
                attrs: {
                  "aria-label":
                    "Forms Library | Frequently Asked Questions Link",
                },
                on: {
                  click: function ($event) {
                    return _vm.logFaqCategoryNavigation(
                      _vm.faqCategoryId,
                      _vm.faqInteraction
                    )
                  },
                },
              },
              [
                _vm._v(
                  "\n        Forms Library | Frequently Asked Questions\n        "
                ),
                _c("arrow-right", { staticClass: "arrow-icon" }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c("span", { staticClass: "mb-3" }, [_vm._v("Select a company")]),
              _c("company-select", {
                staticClass: "mb-3",
                on: { input: _vm.companySelected },
              }),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c("span", { staticClass: "mb-3" }, [
                _vm._v("Select jurisdiction"),
              ]),
              _c("jurisdiction-select", {
                staticClass: "mb-3",
                attrs: { "is-disable": _vm.disableJurisdictionSelect },
                on: { input: _vm.jurisdictionSelectHandler },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  staticClass: "p-2 btn-border-radius w-100 add-service-btn",
                  class: _vm.$mq != "sm" ? "btn-shadow mr-2" : "",
                  attrs: {
                    variant: "primary",
                    "aria-label": "Add a business to my account Button",
                  },
                  on: { click: _vm.newCompanyPage },
                },
                [_vm._v("\n        Add a business to my account\n      ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }